<template>
    <div class="index">
        <!-- 头部导航组件 -->
        <navbar-header></navbar-header>

        <div class="box_title_right" v-if="$route.query.type">
            <span class="el-icon-location-outline"></span><router-link to="/"> 首页 </router-link> / 
            <span @click="$router.go(-1)" style="cursor: pointer;" v-if="$route.query.type == 1"> 新闻公告 </span>
            <span @click="$router.go(-1)" style="cursor: pointer;" v-else>交易资讯</span>
            / <span class="name">{{$route.query.type == 1 ? '新闻公告详情' : '交易资讯详情'}}</span>
        </div>
        <div class="box_title_right" v-else>
            <span class="el-icon-location-outline"></span><router-link to="/"> 首页 </router-link> / 
            <span class="name">详情</span>
        </div>

        <div class="content animate__animated animate__fadeIn">
            <div class="bt">
                {{details.title}}
            </div>
            <div class="fbt">
                {{ new Date(details.createTime).format('yyyy-MM-dd') }} 来源：信息员
            </div>
            <el-divider></el-divider>
            <p class="nr" v-html="details.content"></p>
            <div style="margin-top: 20px;display: flex;flex-direction: row;justify-content: center;">
                <div class="arrow" @click="previousPosts()">上一篇</div>
                <div class="arrow" style="margin-left: 10px;" @click="nextChapter()">下一篇</div>
            </div>
        </div>

        <!-- 底部 -->
        <nav-footer></nav-footer>

    </div>
</template>

<script>
import NavbarHeader from "../components/home/newHeader.vue"
import NavFooter from "../components/common/NavFooter.vue"
export default {
    components: {
        NavbarHeader,
        NavFooter
    },
    data () {
        return {
            details: {},
            query: {},
            page: 0
        }
    },
    computed: {

    },
    mounted () {
        this.query = this.$route.query
        let query = this.$route.query
        this.page = Number((query.page - 1) * query.limit) + Number(query.index) + Number(1)
        this.getNewView();
    },
    methods: {
        getNewView (id) {
            this.$axios.get("/yc/new/view", {
                params: {
                    id: id ? id : this.$route.query.id
                }
            }).then(res => {
                if (res.data.code == '200') {
                    this.details = res.data.data
                }
            })
        },
        //上一篇
        previousPosts () {
            this.page--
            if (this.page == 0) {
                this.page = 1
                return
            }
            this.getNewList({
                page: this.page,
                limit: 1,
                categoryId: this.query.categoryId
            })
        },
        //新闻请求
        getNewList (obj) {
            this.$axios.get("/yc/new/list", {
                params: obj
            }).then(res => {
                if (res.data.code == '200') {
                    this.getNewView(res.data.data.list[0].id)
                }
            })
        },
        //下一篇
        nextChapter () {
            this.page++
            if (this.page > this.query.total) {
                this.page = this.query.total
                return
            }
            this.getNewList({
                page: this.page,
                limit: 1,
                categoryId: this.query.categoryId
            })
        },
    },
}
</script>
<style>
.el-dialog__wrapper {
    z-index: 100000000 !important;
}
.el-select-dropdown {
    z-index: 100000000000000000000000 !important;
}
.el-picker-panel {
    z-index: 100000000000000000000000 !important;
}
</style>

<style lang="scss" scoped>
.index {
    width: 100%;
    background: #f5f5f5;
    &::-webkit-scrollbar {
        display: none;
    }
    .name {
        font-size: 14px;
        color: #000;
    }
}
.box_title_right {
    width: 1200px;
    margin: 0 auto;
    font-size: 15px;
    color: #999999;
    padding: 20px 0;
    a {
        color: #999999;
    }
}
.content {
    width: 1200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 0 auto;
    margin-bottom: 20px;
    min-height: 100vh;
    box-shadow: 0px 2px 20px 0px #e9effa;
    background: #fff;
    padding: 60px 200px 60px 200px;
    .bt {
        font-size: 24px;
        font-weight: 600;
        color: #221f1f;
        line-height: 33px;
        text-align: center;
        margin-bottom: 10px;
    }
    .fbt {
        font-size: 18px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 25px;
        text-align: center;
    }
    .nr {
        overflow: hidden;
        font-size: 14px;
        font-weight: 400;
        color: #221f1f;
        line-height: 32px;
        letter-spacing: 1px;
    }
}
.arrow {
    color: #c50007;
    cursor: pointer;
    width: 74px;
    height: 30px;
    line-height: 28px;
    border-radius: 3px;
    text-align: center;
    border: 1px solid #c50007;
    &:hover {
        color: #fff;
        background: #c50007;
    }
}
</style>